@import "~sanitize.css/sanitize.css";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* background-color: #f9faff; */
  background-color: #ffffff;
}

html,
body,
#root {
  min-height: 100vh;
}

button,
div,
input,
textarea {
  outline: 0;
}

button {
  border: none;
}

a {
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
