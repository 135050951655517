@font-face { /* Bold */
  font-family: 'Poppins';
  src: url('../fonts/poppins-bold.woff2') format('woff2'),
       url('../fonts/poppins-bold.woff') format('woff');
  font-weight: 700;
}

@font-face { /* Semi-Bold */
  font-family: 'Poppins';
  src: url('../fonts/poppins-semibold.woff2') format('woff2'),
       url('../fonts/poppins-semibold.woff') format('woff');
  font-weight: 600;
}

@font-face { /* Medium */
  font-family: 'Poppins';
  src: url('../fonts/poppins-medium.woff2') format('woff2'),
       url('../fonts/poppins-medium.woff') format('woff');
  font-weight: 500;
}

@font-face { /* Regular */
  font-family: 'Poppins';
  src: url('../fonts/poppins-regular.woff2') format('woff2'),
       url('../fonts/poppins-regular.woff') format('woff');
  font-weight: 400;
}

@font-face { /* Light */
  font-family: 'Poppins';
  src: url('../fonts/poppins-light.woff2') format('woff2'),
       url('../fonts/poppins-light.woff') format('woff');
  font-weight: 300;
}

@font-face { /* Extra-Light */
  font-family: 'Poppins';
  src: url('../fonts/poppins-extralight.woff2') format('woff2'),
       url('../fonts/poppins-extralight.woff') format('woff');
  font-weight: 200;
}